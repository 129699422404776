//use {{ $filters.reformat( "SK123456" ) }} or {{ $filters.reformat( "SK123456", 2, "-" ) }}

const filters = {
  reformat(str: string, after = 4, c = " ") {
    if (!str) {
      return "";
    }

    const v = str.replace(/[^\dA-Z]/g, "");
    const firstChar = v.charAt(0);
    const restOfString = v.substring(1);

    const reg = new RegExp(".{" + after + "}", "g");

    return (
      firstChar +
      restOfString
        .replace(reg, function (a) {
          return a + c;
        })
        .replace(/[^0-9]+$/, "")
    );
  },

  /*
   *  Example:
   *  enum: ENDPOINT_VIEW_CAMPAIGN = "/views/:userId/campaigns/:aa",
   *  function: filters.dynamicEnum(Endpoints.ENDPOINT_VIEW_CAMPAIGN, [ { key: ":userId", value: "56" }, { key: ":aa", value: "XX" } ]);
   *  result: "/views/56/campaigns/XX",
   */
  dynamicEnum(str: string, replace: { key: string; value: string }[]) {
    for (const [key, item] of Object.entries(replace)) {
      str = str.replaceAll(item.key, item.value);
    }
    return str;
  },

  /*
   *  Date to local format exp.: $filters.dateToLocaleFormat('1.1.1990 20:59:12')
   */
  dateToLocaleFormat(date: string, getOnlyDate = false) {
    const locale: string | null = localStorage.getItem("locale") || "sk-SK";
    if (getOnlyDate) {
      return new Date(date).toLocaleDateString(locale);
    }

    return new Date(date).toLocaleString(locale);
  },

  /*
   *  Get date local format by locale exp.: $filters.getDateLocaleFormat() || $filters.getDateLocaleFormat('sk-SK')
   */
  getDateLocaleFormat(locale: string) {
    const formats = {
      "af-ZA": "YYYY/MM/DD",
      "am-ET": "D/M/YYYY",
      "ar-AE": "DD/MM/YYYY",
      "ar-BH": "DD/MM/YYYY",
      "ar-DZ": "DD-MM-YYYY",
      "ar-EG": "DD/MM/YYYY",
      "ar-IQ": "DD/MM/YYYY",
      "ar-JO": "DD/MM/YYYY",
      "ar-KW": "DD/MM/YYYY",
      "ar-LB": "DD/MM/YYYY",
      "ar-LY": "DD/MM/YYYY",
      "ar-MA": "DD-MM-YYYY",
      "ar-OM": "DD/MM/YYYY",
      "ar-QA": "DD/MM/YYYY",
      "ar-SA": "DD/MM/yy",
      "ar-SY": "DD/MM/YYYY",
      "ar-TN": "DD-MM-YYYY",
      "ar-YE": "DD/MM/YYYY",
      "arn-CL": "DD-MM-YYYY",
      "as-IN": "DD-MM-YYYY",
      "az-Cyrl-AZ": "DD.MM.YYYY",
      "az-Latn-AZ": "DD.MM.YYYY",
      "ba-RU": "DD.MM.yy",
      "be-BY": "DD.MM.YYYY",
      "bg-BG": "DD.M.YYYY",
      "bn-BD": "DD-MM-yy",
      "bn-IN": "DD-MM-yy",
      "bo-CN": "YYYY/M/D",
      "br-FR": "DD/MM/YYYY",
      "bs-Cyrl-BA": "D.M.YYYY",
      "bs-Latn-BA": "D.M.YYYY",
      "ca-ES": "DD/MM/YYYY",
      "co-FR": "DD/MM/YYYY",
      "cs-CZ": "D.M.YYYY",
      "cy-GB": "DD/MM/YYYY",
      "da-DK": "DD-MM-YYYY",
      "de-AT": "DD.MM.YYYY",
      "de-CH": "DD.MM.YYYY",
      "de-DE": "DD.MM.YYYY",
      "de-LI": "DD.MM.YYYY",
      "de-LU": "DD.MM.YYYY",
      "dsb-DE": "D. M. YYYY",
      "dv-MV": "DD/MM/yy",
      "el-GR": "D/M/YYYY",
      "en-029": "MM/DD/YYYY",
      "en-AU": "D/MM/YYYY",
      "en-BZ": "DD/MM/YYYY",
      "en-CA": "DD/MM/YYYY",
      "en-GB": "DD/MM/YYYY",
      "en-IE": "DD/MM/YYYY",
      "en-IN": "DD-MM-YYYY",
      "en-JM": "DD/MM/YYYY",
      "en-MY": "D/M/YYYY",
      "en-NZ": "D/MM/YYYY",
      "en-PH": "M/D/YYYY",
      "en-SG": "D/M/YYYY",
      "en-TT": "DD/MM/YYYY",
      "en-US": "M/D/YYYY",
      "en-ZA": "YYYY/MM/DD",
      "en-ZW": "M/D/YYYY",
      "es-AR": "DD/MM/YYYY",
      "es-BO": "DD/MM/YYYY",
      "es-CL": "DD-MM-YYYY",
      "es-CO": "DD/MM/YYYY",
      "es-CR": "DD/MM/YYYY",
      "es-DO": "DD/MM/YYYY",
      "es-EC": "DD/MM/YYYY",
      "es-ES": "DD/MM/YYYY",
      "es-GT": "DD/MM/YYYY",
      "es-HN": "DD/MM/YYYY",
      "es-MX": "DD/MM/YYYY",
      "es-NI": "DD/MM/YYYY",
      "es-PA": "MM/DD/YYYY",
      "es-PE": "DD/MM/YYYY",
      "es-PR": "DD/MM/YYYY",
      "es-PY": "DD/MM/YYYY",
      "es-SV": "DD/MM/YYYY",
      "es-US": "M/D/YYYY",
      "es-UY": "DD/MM/YYYY",
      "es-VE": "DD/MM/YYYY",
      "et-EE": "D.MM.YYYY",
      "eu-ES": "YYYY/MM/DD",
      "fa-IR": "MM/DD/YYYY",
      "fi-FI": "D.M.YYYY",
      "fil-PH": "M/D/YYYY",
      "fo-FO": "DD-MM-YYYY",
      "fr-BE": "D/MM/YYYY",
      "fr-CA": "YYYY-MM-DD",
      "fr-CH": "DD.MM.YYYY",
      "fr-FR": "DD/MM/YYYY",
      "fr-LU": "DD/MM/YYYY",
      "fr-MC": "DD/MM/YYYY",
      "fy-NL": "D-M-YYYY",
      "ga-IE": "DD/MM/YYYY",
      "gd-GB": "DD/MM/YYYY",
      "gl-ES": "DD/MM/yy",
      "gsw-FR": "DD/MM/YYYY",
      "gu-IN": "DD-MM-yy",
      "ha-Latn-NG": "D/M/YYYY",
      "he-IL": "DD/MM/YYYY",
      "hi-IN": "DD-MM-YYYY",
      "hr-BA": "D.M.YYYY.",
      "hr-HR": "D.M.YYYY",
      "hsb-DE": "D. M. YYYY",
      "hu-HU": "YYYY. MM. DD.",
      "hy-AM": "DD.MM.YYYY",
      "id-ID": "DD/MM/YYYY",
      "ig-NG": "D/M/YYYY",
      "ii-CN": "YYYY/M/D",
      "is-IS": "D.M.YYYY",
      "it-CH": "DD.MM.YYYY",
      "it-IT": "DD/MM/YYYY",
      "iu-Cans-CA": "D/M/YYYY",
      "iu-Latn-CA": "D/MM/YYYY",
      "ja-JP": "YYYY/MM/DD",
      "ka-GE": "DD.MM.YYYY",
      "kk-KZ": "DD.MM.YYYY",
      "kl-GL": "DD-MM-YYYY",
      "km-KH": "YYYY-MM-DD",
      "kn-IN": "DD-MM-yy",
      "ko-KR": "YYYY. MM. DD",
      "kok-IN": "DD-MM-YYYY",
      "ky-KG": "DD.MM.yy",
      "lb-LU": "DD/MM/YYYY",
      "lo-LA": "DD/MM/YYYY",
      "lt-LT": "YYYY.MM.DD",
      "lv-LV": "YYYY.MM.DD.",
      "mi-NZ": "DD/MM/YYYY",
      "mk-MK": "DD.MM.YYYY",
      "ml-IN": "DD-MM-yy",
      "mn-MN": "yy.MM.DD",
      "mn-Mong-CN": "YYYY/M/D",
      "moh-CA": "M/D/YYYY",
      "mr-IN": "DD-MM-YYYY",
      "ms-BN": "DD/MM/YYYY",
      "ms-MY": "DD/MM/YYYY",
      "mt-MT": "DD/MM/YYYY",
      "nb-NO": "DD.MM.YYYY",
      "ne-NP": "M/D/YYYY",
      "nl-BE": "D/MM/YYYY",
      "nl-NL": "D-M-YYYY",
      "nn-NO": "DD.MM.YYYY",
      "nso-ZA": "YYYY/MM/DD",
      "oc-FR": "DD/MM/YYYY",
      "or-IN": "DD-MM-yy",
      "pa-IN": "DD-MM-yy",
      "pl-PL": "DD.MM.YYYY",
      "prs-AF": "DD/MM/yy",
      "ps-AF": "DD/MM/yy",
      "pt-BR": "D/M/YYYY",
      "pt-PT": "DD-MM-YYYY",
      "qut-GT": "DD/MM/YYYY",
      "quz-BO": "DD/MM/YYYY",
      "quz-EC": "DD/MM/YYYY",
      "quz-PE": "DD/MM/YYYY",
      "rm-CH": "DD/MM/YYYY",
      "ro-RO": "DD.MM.YYYY",
      "ru-RU": "DD.MM.YYYY",
      "rw-RW": "M/D/YYYY",
      "sa-IN": "DD-MM-YYYY",
      "sah-RU": "MM.DD.YYYY",
      "se-FI": "D.M.YYYY",
      "se-NO": "DD.MM.YYYY",
      "se-SE": "YYYY-MM-DD",
      "si-LK": "YYYY-MM-DD",
      "sk-SK": "D.M.YYYY",
      "sl-SI": "D.M.YYYY",
      "sma-NO": "DD.MM.YYYY",
      "sma-SE": "YYYY-MM-DD",
      "smj-NO": "DD.MM.YYYY",
      "smj-SE": "YYYY-MM-DD",
      "smn-FI": "D.M.YYYY",
      "sms-FI": "D.M.YYYY",
      "sq-AL": "YYYY-MM-DD",
      "sr-Cyrl-BA": "D.M.YYYY",
      "sr-Cyrl-CS": "D.M.YYYY",
      "sr-Cyrl-ME": "D.M.YYYY",
      "sr-Cyrl-RS": "D.M.YYYY",
      "sr-Latn-BA": "D.M.YYYY",
      "sr-Latn-CS": "D.M.YYYY",
      "sr-Latn-ME": "D.M.YYYY",
      "sr-Latn-RS": "D.M.YYYY",
      "sv-FI": "D.M.YYYY",
      "sv-SE": "YYYY-MM-DD",
      "sw-KE": "M/D/YYYY",
      "syr-SY": "DD/MM/YYYY",
      "ta-IN": "DD-MM-YYYY",
      "te-IN": "DD-MM-yy",
      "tg-Cyrl-TJ": "DD.MM.yy",
      "th-TH": "D/M/YYYY",
      "tk-TM": "DD.MM.yy",
      "tn-ZA": "YYYY/MM/DD",
      "tr-TR": "DD.MM.YYYY",
      "tt-RU": "DD.MM.YYYY",
      "tzm-Latn-DZ": "DD-MM-YYYY",
      "ug-CN": "YYYY-M-D",
      "uk-UA": "DD.MM.YYYY",
      "ur-PK": "DD/MM/YYYY",
      "uz-Cyrl-UZ": "DD.MM.YYYY",
      "uz-Latn-UZ": "DD/MM YYYY",
      "vi-VN": "DD/MM/YYYY",
      "wo-SN": "DD/MM/YYYY",
      "xh-ZA": "YYYY/MM/DD",
      "yo-NG": "D/M/YYYY",
      "zh-CN": "YYYY/M/D",
      "zh-HK": "D/M/YYYY",
      "zh-MO": "D/M/YYYY",
      "zh-SG": "D/M/YYYY",
      "zh-TW": "YYYY/M/D",
      "zu-ZA": "YYYY/MM/DD",
    };
    const _locale: string | null = localStorage.getItem("locale") || "sk-SK";
    return formats[locale !== undefined ? locale : _locale];
  },

  /*
   *  Date from local to MySQL format exp.: $filters.dateToMysqlFormat('9/27/2022 22:00:00'))
   */
  dateToMysqlFormat(date: string) {
    return new Date(date).toISOString().slice(0, 19).replace("T", " ");
  },

  /*
   *  Date from local to MySQL format exp.: $filters.dateToMysqlFormat('9/27/2022 22:00:00'))
   */
  formatNumber(value: number) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },

  /*
   *  exp.: $filters.prettyBytes('100000'))
   */
  prettyBytes(num: number) {
    if (typeof num !== "number" || isNaN(num)) {
      throw new TypeError("Expected a number");
    }

    const neg = num < 0;
    const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    if (neg) {
      num = -num;
    }

    if (num < 1) {
      return (neg ? "-" : "") + num + " B";
    }

    const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
    num = Number((num / Math.pow(1000, exponent)).toFixed(2)) * 1;
    const unit = units[exponent];

    return (neg ? "-" : "") + num + " " + unit;
  },

  /*
   *  exp.: $filters.getJsonFromUrl('transactions?filter[or][rtype][eq]=A&filter[or][track_method][eq]=F&sort=-id'))
   */
  getJsonFromUrl(url: string) {
    if (!url) url = location.href;
    const question = url.indexOf("?");
    let hash = url.indexOf("#");
    if (hash == -1 && question == -1) return {};
    if (hash == -1) hash = url.length;
    const query = question == -1 || hash == question + 1 ? url.substring(hash) : url.substring(question + 1, hash);

    const params = new URLSearchParams(query);
    const queryParams = {};
    for (const [key, value] of params) {
      if (key.endsWith("[]")) {
        const paramKey = key.slice(0, -2);
        queryParams[paramKey] = queryParams[paramKey] || [];
        queryParams[paramKey].push(value);
      } else {
        queryParams[key] = value;
      }
    }
    //return Object.fromEntries(new URLSearchParams(query));
    return queryParams;
  },

  buildCategoryTree(categories) {
    return categories.filter((category) => category.parent_id === 1);
  },

  //percentage calculation for progress bar
  percentageCalculation(total: number, pending: number, failed: number) {
    return Math.round(((total - (pending - failed)) / total) * 100);
  },
};
export default filters;
