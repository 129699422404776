export default {
  updated(el, binding) {
    function h() {
      if (typeof v == "number" || !v) {
        if (!v) v = 300;
        el.disabled = true;

        if (!v) {
          return;
        }

        setTimeout(() => {
          el.disabled = false;
        }, v);
      } else if (typeof v == "boolean") {
        el.disabled = !v;
      }
    }

    rm();

    let v = binding.value;
    if (!v) {
      el.disabled = v;
    }

    el.addEventListener("click", h, { once: true });

    function rm() {
      el.removeEventListener("click", h);
    }
  },
};
