import { RouteRecordRaw } from "vue-router";

export const IFrames: Array<RouteRecordRaw> = [
  {
    path: "/campaign-feed-iframe",
    name: "campaign-feed-iframe-index",
    component: () => import("@/views/iframes/CampaignFeedIFrameIndex.vue"),
    meta: { resources: ["CampaignFeed"] },
  },
];

export default IFrames;
