import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";

interface ColumnType {
  name: string;
  field: string;
  hidden: boolean;
}

interface FilterType {
  field: string;
  value: any[];
}

interface ReportsState {
  initialColumns: ColumnType[];
  columns: ColumnType[];
  selectedFilters: Record<string, FilterType[]>;
}

@Module({ namespaced: true, store })
class ReportsStore extends VuexModule implements ReportsState {
  public initialColumns: ColumnType[] = [];
  public columns: ColumnType[] = [];
  public selectedFilters: Record<string, FilterType[]> = {};

  @Mutation
  setInitialColumns(payload: any) {
    this.initialColumns = payload;
  }

  @Mutation
  setColumns(payload: any) {
    this.columns = payload;
  }

  @Mutation
  resetColumns() {
    this.columns = [...this.initialColumns];
  }

  @Mutation
  addFilter({ path, filter }: { path: string; filter: FilterType }) {
    if (!this.selectedFilters[path]) {
      this.selectedFilters[path] = [];
    }

    const existingIndex = this.selectedFilters[path].findIndex((f) => f.field === filter.field);

    if (existingIndex !== -1) {
      this.selectedFilters[path][existingIndex] = filter;
    } else {
      this.selectedFilters[path].push(filter);
    }
  }

  @Mutation
  removeFilter({ path, field }: { path: string; field: string }) {
    if (this.selectedFilters[path]) {
      this.selectedFilters[path] = this.selectedFilters[path].filter((f) => f.field !== field);
    }
  }

  @Mutation
  resetFilters(path: string) {
    this.selectedFilters[path] = [];
  }

  @Action
  async updateColumns(newColumns: any) {
    // const columnMap = currentColumns.map((col) => [col.field, col]);
    //
    // newColumns.forEach((col) => {
    //   console.log(col);
    //   if (columnMap.has(col.field)) {
    //     const existingCol: any = columnMap.get(col.field);
    //     for (const key in col) {
    //       existingCol[key] = col[key];
    //     }
    //   } else {
    //     columnMap.set(col.field, col);
    //   }
    // });
    //
    // this.context.commit("setInitialColumns", Array.from(columnMap.values()));
    // this.context.commit("setColumns", newColumns);
  }

  @Action
  resetToInitialColumns() {
    this.context.commit("resetColumns");
  }

  @Action
  updateFilter({ path, filter }: { path: string; filter: FilterType }) {
    this.context.commit("addFilter", { path, filter });
  }

  @Action
  deleteFilter({ path, field }: { path: string; field: string }) {
    this.context.commit("removeFilter", { path, field });
  }

  @Action
  clearFilters(path: string) {
    this.context.commit("resetFilters", path);
  }

  get getFilters() {
    return (path: string) => this.selectedFilters[path] || [];
  }

  get getInitialColumns() {
    return this.initialColumns;
  }
}

export default ReportsStore;
